.ControlsWrapper {
  /* border-right: 10px solid transparent; */
  flex: 0 1 60px;
  /* padding: 0 1em; */
  visibility: hidden;
  transition: 1s border-color ease-in-out;

  height: 0px;
  cursor: pointer;

  position: sticky;
  top: 50px;

  z-index: 20;
}

@media screen and (min-width: 800px) {
  .ControlsWrapper {
    padding-right: 1em;
  }
}

.Controls {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ControlsFrame {
  border: 3px solid transparent;
  border-radius: 3px;
}

.ControlsFrame:hover {
  /* border-color: teal; */
  box-shadow: 0px 0px 10px gray;
}

/* .InFocus .ControlsWrapper, */
/* .Block:focus .ControlsWrapper */
.Block:hover .ControlsWrapper {
  visibility: unset;
}

.Block:focus {
  outline: none;
}

/* .Block:focus .BlockWrapper{
    outline: medium
} */

.BlockWrapper {
  flex: auto;

  border-left: 0px solid transparent;
  /* padding-left: 10px; */
  /* padding-left: 20px; */
  width: 100%;
}
.LeftMargin,
.RightMargin {
  flex: 0 0 50px;
}

@media all and (max-width: 600px) {
  .LeftMargin,
  .RightMargin {
    display: none;
  }
}

.Block {
  margin: 18px 0;
  padding: 18px 0;
  /* padding-left: 10px; */
  /* border-left: 10px solid transparent; */
  display: flex;
}

/* 
.Block:focus .BlockWrapper,
.Block:focus .ControlsWrapper {
    border-color: gray;
} */

.InFocus.Block .BlockWrapper,
.InFocus.Block .ControlsWrapper {
  border-color: lightblue;
}

.Block:hover .BlockWrapper,
.Block:hover .ControlsWrapper {
  border-color: #eee;
}

.Block[data-blocktype='header'] {
  margin-bottom: -10px;
  padding: 0px;
  padding-top: 10px;
}

.Block[data-blocktype='header'] h2 {
  font-size: 27px;
  padding: 0 10px;
}

@media screen and (max-width: 600px) {
  .Block[data-blocktype='header'] h2 {
    font-size: 24px;
  }
}

.Block[data-blocktype='blockquote'] .BlockWrapper,
.Block[data-blocktype='header'] .BlockWrapper {
  margin-left: 10px;
}

.Block[data-blocktype='footnoteParagraph']:hover .BlockWrapper,
.Block[data-blocktype='footnoteParagraph'] .ControlsWrapper {
  border-color: #ccc0a6;
}

.Block[data-blocktype='paragraph'] .BlockWrapper {
  padding: 0px 10px;
}

.Delimiter {
  text-align: center;
  font-size: 2em;
  letter-spacing: 0.3em;
  color: #222;
}
