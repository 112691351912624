.Footnote {
  font-size: 14px;
  line-height: unset;
  background-color: #f9f5ef;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5em;

  position: relative;
}

.NavButtonTray {
  flex: 0 0 64px;
  display: flex;
  justify-content: flex-end;

  /* border: 10px solid red; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: calc(100% - 64px);
}

.NavButtonTray button {
  width: 32px;
  height: 32px;
  /* background-color: tan; */
  background-color: #d5cbb7;
  fill: white;
  border: none;
  color: white;
  /* font-size: 20px; */
  opacity: 1;
  transition: 1s opacity;
  padding: 0;
}

.NavButtonTray button:hover {
  background-color: #ccc0a6;
  transition: background-color 0.5s;
  cursor: pointer;
}

.NavButtonTray button:disabled {
  opacity: 0;
  display: none;
  width: 0px;
}

.ButtonTray {
  /* display: none; */
  display: flex;
  justify-content: flex-end;
  line-height: unset;
}

/* .ContentArea{
    overflow: hidden;
} */

.FootnoteTextGroup {
  display: flex;
  /* overflow: hidden; */
  /* padding: 1em; */
  box-sizing: border-box;

  font-size: 13px;
  line-height: 1.75em;
}

.FootnoteLabel {
  /* font-weight: lighter; */
  /* padding: 0 1em; */
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  top: 0;
  left: 0px;
  height: auto;
  background-color: #d5cbb7;
  color: white;
  font-weight: 500;

  position: absolute;
}

[data-blocktype='footnoteParagraph']:hover .FootnoteLabel {
  background-color: #ccc0a6;
  transition: background-color 1s;
  color: white;
}

.FootnoteText {
  flex: 1;
  /* padding-right: 1em;
  padding-left: 1em; */
  padding-top: 32px;
  padding-bottom: 32px;

  word-break: break-word;
  overflow: hidden;
}

.TextContent {
  margin: 0px 32px;
}
