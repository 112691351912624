.Paragraph {
  /* background-color: #efefef; */
  padding-left: 10px;
  padding-right: 10px;
  font-size: 21px;
  line-height: 1.75em;
  color: #333;

  /* Chrome hack: https://stackoverflow.com/questions/11289166/chrome-on-android-resizes-font */
  max-height: 999999px;
}

@media screen and (max-width: 600px) {
  .Paragraph {
    font-size: 19px;
  }
  /* .Paragraph[data-paragraph-type="paragraph"] {
    padding: 0px 40px;
  } */
}

.Paragraph[data-paragraph-type='blockquote'] {
  padding: 1em 2em;
  font-style: italic;
  background-color: #efefef;
  border-radius: 3px;
}

button.FootnoteLink {
  background-color: transparent;
  border: none;
}

.FootnoteLink {
  cursor: pointer;
  /* background-color: #555; */
  color: white;
  color: rgb(0, 100, 100);
  fill: teal;
  /* border: 1px solid #555; */
  /* border-radius: 3px; */
  /* padding: 0 0.5em; */

  margin: 0 0.1em;
  text-decoration: none;
  /* background-color: beige; */
  /* font-size: 12px;
    line-height: 1em; */
  /* box-shadow: 1px 1px 0px #999; */
  font-size: 0.75em;
}

.FootnoteLink:hover {
  /* box-shadow: 4px 4px 0px #999; */
  /* border-color:beige */
  color: orangered;
  fill: orangered;
  background-color: #f9f5ef;
  border-radius: 2px;
}
