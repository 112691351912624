/* .LogoBar {
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  box-shadow: 0px 0px 10px #ddd;
  position: relative;
  top: 0;
} */

.BlueBox {
  background-color: black;
  /* width: 60px; */
  height: 60px;
}

.BlueBox:hover {
  opacity: 0.8;
}

.BlueBox svg {
  height: 60px;
  background-color: white;
}

.LogoBar svg {
  height: 60px;
  flex: 0 0 60px;
  background-color: white;
}

.BlueBox img {
  height: 60px;
  background-color: white;
  overflow: hidden;
}
/* 
.LogoBar:hover {
  opacity: 0.8;
} */

.LogoText {
  /* flex-grow: 1; */
  /* text-transform: uppercase; */
  font-feature-settings: 'smcp' 1;
  letter-spacing: 0.12em;
  font-size: 21px;
  color: #333;
  text-align: center;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;

  /* font-size: 19px; */
}

.LogoText svg {
  height: 23px;
}

.Bar {
  display: flex;
  justify-content: space-between;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 100;

  background-color: white;
}

.BigBar {
  position: sticky;
  top: 0;
  height: 60px;
}

.SmallBar {
  position: sticky;
  top: 0;
  height: 30px;
  box-shadow: 0px 0px 10px #999;
}

a.BarLink {
  text-decoration: none;
}
/* 
.BarLink:hover {
  opacity: 0.5;
} */

.BackButton {
  width: 60px;
  height: 60px;
  justify-content: center;
  display: block;

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FullAppLogo {
  height: 60px;
  display: flex;
  color: #444;
  text-transform: lowercase;
  margin-right: 80px;
  font-size: 22px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FullAppLogo:hover {
  color: #0d99ab;
}

.BackButton,
.FullAppLogo {
  display: none;
}

@media screen and (max-width: 800px) {
  .BackButton {
    display: flex;
  }
}

@media screen and (min-width: 801px) {
  .FullAppLogo {
    display: flex;
  }
}
