@font-face {
  font-family: 'YaleNewRoman';
  src: url('./fonts/yalenew-roman-webfont.woff2') format('woff2'),
    url('./fonts/yalenew-roman-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'YaleNewRoman';
  src: url('./fonts/yalenew-bold-webfont.woff2') format('woff2'),
    url('./fonts/yalenew-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'YaleNewRoman';
  src: url('./fonts/yalenew-italic-webfont.woff2') format('woff2'),
    url('./fonts/yalenew-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'YaleNewRoman';
  src: url('./fonts/yalenew-bolditalic-webfont.woff2') format('woff2'),
    url('./fonts/yalenew-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'YaleDesign';
  src: url('./fonts/yaledessmacap-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Mallory';
  src: url('./fonts/Mallory-Medium.woff'), url('./fonts/Mallory-Medium.woff2');
  font-weight: normal;
  font-style: normal;
}

.sans-title-ff {
  font-family: 'Mallory', Arial, Helvetica, sans-serif;
}

.sans-copy-ff {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.serif-title-ff,
.serif-copy-ff {
  font-family: 'YaleNewRoman', 'Times New Roman', Times, serif;
  line-height: 1.5;
}
