.EssayIndexItem {
  /* margin: px 0px; */
  display: flex;

  flex-direction: column;
  padding: 10px;
  border-radius: 3px;
}

.EssayIndexItem:hover {
  background-color: #efefef;
}

.ItemLink:focus-within .EssayIndexItem {
  outline: 4px solid blue;
}

.Byline {
  margin-top: 0px;
  color: #444;
}

/* .ThumbnailArea {
  flex: 0 0 320px;
} */

.TextArea {
  flex: 1 1 1;
}

.ThumbnailArea {
  overflow: hidden;
}

.ThumbnailArea video {
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  object-fit: contain;
  border: 2px solid #222;
  background-color: black;

  filter: saturate(50%);

  min-height: 75%;
}

.ThumbnailArea:hover video {
  filter: unset;
}

.TextArea {
  /* padding: 0 33px; */
  padding-left: 18px;
}

a.ItemLink {
  text-decoration: none;
  color: inherit;
}

a.ItemLink:hover {
  color: #0d99ab;
}

.TextArea .Title {
  font-size: 30px;
  font-weight: normal;
}

.TextArea {
  font-size: 14px;
}

p.SuperTitle {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 0;
}

/* @media all and (max-width: 800px) { */
/* .EssayIndexItem {
  flex-direction: column;
  margin-bottom: 32px;
  box-shadow: 0px 0px 10px #aaa;
} */
/* } */
