.Image {
  display: flex;
  justify-content: center;
}

.Image img {
  max-height: 75vh;
  max-width: 100%;
  /* width: 100%; */
  height: 100%;
  overflow: hidden;
}

