.Viewer {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.Affiliation {
  font-size: 13px;
}

.ContentBodyContainer {
  background-color: white;
  /* position: relative; */
  z-index: 10;
  /* top: 33vh; */
  /* top: 75px;
  left: 0;
  bottom: 0;
  right: 0; */

  max-width: 1070px; /*1080px;*/
  margin-left: auto;
  margin-right: auto;
}

.HeaderAreaContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
  border-top: 1px solid white;
  background-color: white;
  /* box-shadow: 0px 0px 6px #999; */
  height: 22px;

  /* prevent from taking more than one line*/
  /* overflow: hidden; */
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #222;
}

.SplashTitleContainer {
  position: relative;
  z-index: 40;
}

/* .HeaderAreaContainer {
    position: sticky;
    top: 0;
} */

.LogoBarContainer {
  overflow: hidden;
  height: 60px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.PageContent {
  overflow: scroll;
  flex: 100%;
  flex-shrink: 1;
  flex-grow: 0;
}

.SplashTitleContainer {
  position: relative;
  height: 50vh;
  overflow: hidden;
  box-sizing: border-box;
  /* background-color: white;; */
  /* background-color: #222; */
  /* box-shadow: 0px 0px 10px gray; */
}

@media screen and (max-height: 500px) {
  .SplashTitleContainer {
    height: calc(100vh - 60px);
  }
}

.SplashBackgroundVideoContainer {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

.SplashBackgroundImage {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.SplashBackgroundVideo {
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 800px;
  max-height: 100%;
  height: 100%;
}

.SplashTitleTail {
  height: 80px;
  background: white;
  /* background-color:white; */
  /* background: linear-gradient(red 0%, orange 25%, yellow 50%, green 75%, blue 100%); */
  /* background: linear-gradient(white 0%, transparent 100%); */
  /* background: linear-gradient(transparent 0%, white 100%); */
  /* background: linear-gradient(45deg, transparent 0%, white 100%); */

  /* border-top: 1px solid black; */
  /* border-bottom: 1px solid black; */

  /* background: linear-gradient(white 0%, transparent 100%) */
}

.SplashTitle {
  position: relative;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* margin: 0 1em; */
  /* color: #222; */
  color: white;
  height: 100%;
  padding: 0 30px 30px 30px;
  background-color: rgba(0, 0, 0, 0.3);
  text-shadow: 0px 0px 9px black;
}

.Gradient {
  position: absolute;
  z-index: 100;
  top: 0;
  left: calc(50% - 400px);
  right: calc(50% - 400px);
  bottom: 0;

  background-image: linear-gradient(
    to right,
    black 10%,
    rgba(0, 0, 0, 0.1) 49%,
    rgba(0, 0, 0, 0.1) 51%,
    black 90%
  );
}

.SuperTitle {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.SplashMeta {
  font-size: 24px;
}

.SplashTitle h1 {
  max-width: 600px;
  font-size: 56px;
  font-weight: 400;
  line-height: 1em;
  margin: 0px;
  margin: 0 0 0.75em;

  /* color: white; */
  /* line-height: 1.4em; */
  /* font-size: 1em; */
  /* margin-bottom: 1em; */
  /* color: white; */
}

@media all and (max-width: 400px) {
  .SplashTitle h1 {
    font-size: 32px;
  }

  .SuperTitle {
    font-size: 11px;
  }
}

@media all and (min-width: 400px) and (max-width: 600px) {
  .SplashTitle h1 {
    font-size: 36px;
  }

  .SuperTitle {
    font-size: 11px;
  }
}

@media all and (min-width: 600px) and (max-width: 800px) {
  .SplashTitle h1 {
    font-size: 46px;
  }
}

.BrandingNav {
  user-select: none;
  background-color: #111;
  cursor: pointer;
  position: sticky;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  font-weight: bold;
  justify-content: center;
}

a.BrandingNavAnchor {
  color: white;
  text-decoration: none;
}

.BrandingNavItem {
  font-size: 19px;
  font-weight: 500;
  line-height: 38px;
}
