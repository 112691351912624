.EssayPreamble {
  background-color: #efefef;
  color: #555;
  font-size: 13px;
  padding: 2px 30px;
  font-weight: 500;
}

.EssayPreamble svg {
  height: 0.7em;
  width: 0.7em;
  margin-right: 1em;
}

.EssayPreamble a {
  color: inherit;
}
