.CallToActionOuter {
  border-top: 2px solid #efefef;
  /* padding-left: 70px;
  padding-right: 70px; */
  margin: 0 70px;
  padding-bottom: 20px;
}
.CallToActionInner {
  margin-top: 13px;
  padding-top: 26px;
  /* max-width: 800px; */
  margin-left: auto;
  margin-right: auto;

  display: flex;
}

@media screen and (max-width: 800px) {
  .CallToActionOuter {
    margin: 0px;
    background-color: #efefef;
    padding-bottom: 22px;
  }

  .CallToActionInner {
    display: block;
    /* width: 50%; */
    max-width: 600px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .IntervieweeName {
    font-size: 23px;
  }

  .TextArea {
    display: none;
  }
}

.VideoPoster {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.VideoContainer {
  /* height: 200px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex: 1;
  position: relative;
  background-color: black;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
}

@media screen and (max-width: 500px) {
  .VideoContainer {
    /* height: 200px; */
  }
}

/* .CallToActionInner:hover .VideoContainer {
  border-color: #0d99ab;
} */

img.VideoPreview {
  width: 100%;
  opacity: 1;
}
/* 
.CallToActionInner:hover img.VideoPreview {
  opacity: 1;
} */

.TextArea {
  margin: 0px 24px;
  flex: 2;
  display: flex;
  justify-content: flex-end;

  flex-direction: column;
}

a.CallToActionLink {
  text-decoration: none;
  color: inherit;
}

.OverLine {
  font-weight: 400;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}

.IntervieweeName {
  font-size: 32px;
  line-height: 1.1em;
}

.CallNumber {
  font-size: 13px;
  font-weight: 500;
  color: #777;
  margin-top: 3em;
}

.CallToActionInner:hover .OverLay {
  opacity: 1;
}

.OverLay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 10px 0px 10px 20px;

  color: white;
  fill: white;
  text-shadow: 0px 0px 10px black;

  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.PlayCircle {
  height: 40px;
  width: 40px;
}

.OverLayText {
  padding-left: 14px;
}

.ButtonContainer {
  margin-top: 22px;
}
