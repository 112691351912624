.ImpactHeader {
  height: 50vh;
  background-color: black;
  color: white;

  text-shadow: 0px 0px 10px black;

  position: relative;
}

@media screen and (max-height: 500px) {
  .ImpactHeader {
    height: calc(100vh - 60px);
  }
}

.Background {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10;
}

.Gradient {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
}

.TextArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  /* margin: 0 0 30px 30px; */
  position: relative;

  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 38px;
  z-index: 20;
}

h1.Title {
  font-size: 56px;
  line-height: 1em;
  padding-left: 10px;
}

.TagLine {
  font-size: 26px;
  /* line-height: 0.25em; */
  /* line-height: 1em; */
  margin: 10px 0;
  font-style: italic;
  padding-left: 12px;
}

@media screen and (max-width: 800px) {
  h1.Title {
    font-size: 46px;
  }

  .TagLine {
    font-size: 19px;
  }
}

.Caption {
  position: absolute;
  bottom: 6px;
  right: 60px;
  z-index: 30;
  font-size: 13px;
  color: #cfcfcf;
}

@media all and (max-width: 600px) {
  .Caption {
    visibility: hidden;
  }
}
