.FootnoteCount {
  color: #6e6e6e;
  fill: #999;
  padding-top: 0.6em;
}

.IconContainer {
  display: flex;
  justify-content: center;
}

.IconContainer svg {
  width: 20px;
  height: 20px;
}

.Label {
  font-size: 13px;
  text-align: center;
}
