.App {
  color: #333;
  /* margin-bottom: 100px; */
  /* height: 100vh;
  overflow: hidden; */
}

a {
  /* text-decoration: none; */
  /* color: inherit; */
}

a:hover {
  /* text-decoration: underline; */
  color: #0d99ab;
}

.teal-font {
  color: #0d99ab;
}

.teal-svg {
  fill: #0d99ab;
}

.teal-svg:hover {
  fill: teal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .Block.blocktype-paragraph:first-child ::first-letter{
  /* color:red; 
  float: left;
  font-size: 200px;
  padding-top: 4px;
  padding-right: 8px;
  font-weight: 900;
  color: #222;
} */
