.CenterColumn {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.IndexHeader {
  margin: 40px 60px;
  padding: 0 10px;
}

.IndexHeader .Title {
  margin-bottom: 16px;
}

@media all and (max-width: 800px) {
  .IndexHeader {
    margin: 20px 10px;
    font-size: 13px;
  }
}

@media all and (max-width: 1000px) {
  .IndexPage {
    padding: 20px;
  }
}

.ItemListContainer {
  display: flex;
  flex-wrap: wrap;

  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.TextOnly.ItemListContainer {
  display: block;
  margin-left: unset;
  margin-right: unset;
}

.TextOnly .IndexItemContainer {
  max-width: unset;
  margin-left: 40px;
  margin-right: 40px;
}

.IndexItemContainer {
  flex: 1 0 50%;

  max-width: 500px;
  /* margin-left: auto;
  margin-right: auto; */
}

@media all and (max-width: 1000px) {
  .IndexItemContainer {
    flex: 0 0 100%;
  }
}
